<template>
  <div>
    <v-form>
      <p class="mb-3">
        The image shows thin-layer chromatography results for two samples, A and B. Distances from
        the origin to the center of each sample and the distance from the origin to the solvent
        front have been provided.
      </p>

      <ul class="mb-3">
        <li>Sample A = 2.8 cm</li>
        <li>Sample B = 4.2 cm</li>
        <li>Solvent front = 6.8 cm</li>
      </ul>

      <p class="mb-3">
        Calculate the
        <stemble-latex content="$R_f$" />
        values for Samples A & B.
      </p>

      <v-radio-group v-model="inputs.studentAnswer" :disabled="!allowEditing" class="mb-0 pl-6">
        <v-radio
          v-for="option in optionsShuffled"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemUCI51LBA7_Q4',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
      options: [
        {
          text: '2.4, 1.6',
          value: 'a',
        },
        {
          text: '0.41, 0.62',
          value: 'b',
        },
        {
          text: '0.41, 1.6',
          value: 'c',
        },
        {
          text: '2.4, 0.62',
          value: 'd',
        },
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
